import React from "react";

import logo from "./logo.svg";

import "./App.css";
import Header from "./components/Header";

function App() {
	return (
		<div className="App">
			<Header />
			<h1>Welcome to Scerv!</h1>
		</div>
	);
}

export default App;

